import React from 'react';
import { Buffer } from 'buffer';
import { QRCodeSVG } from 'qrcode.react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import dayjs from 'dayjs';
import './App.css';
import { Login, Loading, Offline } from './components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function App() {
  const [isOffline, setIsOffline] = React.useState(false);

  const handleOffline = React.useCallback(() => {
    setIsOffline(true);
  }, []);

  const handleOnline = React.useCallback(() => {
    setIsOffline(false);
  }, []);

  React.useEffect(() => {
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
  }, [handleOffline, handleOnline]);

  const [data, setData] = React.useState();
  const sse = React.useRef();
  const tokenFromStorage = React.useMemo(() => localStorage.getItem('token'), []);
  const [token, setToken] = React.useState(tokenFromStorage);
  const terminalFromStorage = React.useMemo(() => localStorage.getItem('terminal'), []);
  const [terminal, setTerminal] = React.useState(terminalFromStorage);

  const fetchQrContent = React.useCallback(async () => {
    sse.current = new EventSourcePolyfill(
      `${BASE_URL}/api/attendance/qrcontent?terminal=${terminal}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    sse.current.onmessage = (e) => {
      setData(e.data);
      if (isOffline) {
        setIsOffline(false)
      }
    };
    sse.current.onerror = (e) => {
      if (`${e.error.message}`.includes('No activity within')) {
        setIsOffline(true);
      }
    }
  }, [terminal, isOffline]);

  const handleSubmit = React.useCallback(async (...args) => {
    try {
      const res = await fetch(`${BASE_URL}/api/terminal/auth`, {
        method: 'POST',
        body: JSON.stringify({ terminal: args[0], password: args[1] }),
        headers: { 'Content-Type': 'application/json' }
      });
      if (res.status === 401) {
        alert('Terminal and/or password is incorrect');
      }
      const data = await res.json();
      if (data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('terminal', args[0]);
        setToken(data.token);
        setTerminal(args[0]);
      }
    } catch (err) {
      alert(err.message);
    }
  }, []);
  
  const handleTerminate = () => {
    if (sse.current) {
      sse.current.close();
    }
    localStorage.clear();
    setToken(null);
  };

  React.useEffect(() => {
    if (token) {
      fetchQrContent();
    }

    return () => {
      if (sse.current) {
        sse.current.close();
      }
    };
  }, [token, fetchQrContent]);

  const { time } = React.useMemo(() => {
    if (!data) return { time: Math.floor(new Date().getTime() / 1000) };
    return JSON.parse(Buffer.from(data, 'base64').toString('ascii'));
  }, [data]);

  if (!token) {
    return <Login onSubmit={handleSubmit} />;
  }

  if (!data) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="terminal">Terminal {terminal}</div>
      <button type="button" onClick={handleTerminate}>
        [terminate]
      </button>
      {isOffline ? <Offline /> : (
        <>
          <div>
            SCAN MENGGUNAKAN APLIKASI ABSENSI
          </div>
          <div className="time">
            {dayjs.unix(time).format("dddd, DD MMMM YYYY HH:mm:ss")}
          </div>
          <div className="qrcode-wrapper">
            <QRCodeSVG
              className="qrcode"
              value={data}
              imageSettings={{ excavate: true, width: 20, height: 20 }}
            />
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
            />
          </div>
        </>
      )}
      <img className="powered" src={`${process.env.PUBLIC_URL}/powered-by-schoolink.png`} alt="Powered by SchooLink" />
    </div>
  );
}

export default App;
