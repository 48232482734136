import React from 'react';
import './Offline.css';

function Offline() {
  return (
    <div className="offline-message">
      <h3>Sambungan terputus</h3>
      <p>Harap periksa sambungan internet.</p>
    </div>
  );
}

export default Offline;
