import React from 'react';
import './Login.css';

function Login({ onSubmit }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e.target[0].value, e.target[1].value);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <input type="text" placeholder="Terminal" />
      <input type="password" placeholder="Password" />
      <button type="submit">Login</button>
    </form>
  );
}

export default Login;
